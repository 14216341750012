/**
 * Gida Slider - Show
 *
 * @author Takuto Yanagida
 * @version 2022-11-04
 */


window.GIDA = window['GIDA'] ?? {};

window.GIDA.sliders = window.GIDA['sliders'] ?? {};

window.GIDA.slider_show = function (id, opts = {}) {
	const NS          = 'gida-slider-show';
	const CLS_FRAME   = NS + '-frame';
	const CLS_SLIDES  = NS + '-slides';
	const CLS_VISIBLE = 'visible';
	const CLS_START   = 'start';
	const CLS_VIEW    = 'view';
	const CLS_PAUSE   = 'pause';
	const CLS_SCROLL  = 'scroll';
	const OFFSET_VIEW = 100;

	const root = id ? document.getElementById(id) : document.getElementsByClassName(NS)[0];
	if (!root) return;

	const effectType   = opts['effect_type']        ?? 'slide';  // 'scroll' or 'fade'
	const timeDur      = opts['duration_time']      ?? 8;  // [second]
	const timeTran     = opts['transition_time']    ?? 1;  // [second]
	const randomTiming = opts['random_timing']      ?? false;
	let bgVisible      = opts['background_visible'] ?? true;
	let sideVisible    = opts['side_slide_visible'] ?? false;

	root.style.setProperty('--transition-time', `${timeTran}s`);

	if (effectType !== 'scroll') sideVisible = false;
	if (sideVisible) {
		bgVisible = false;
		const ss = root.querySelector('.' + CLS_SLIDES);
		ss.style.overflow = 'visible';
	}

	const lis  = Array.prototype.slice.call(root.querySelectorAll(`.${CLS_SLIDES} > li`));
	const size = lis.length;
	const sss  = [];
	let effect = null;

	let onTransitionEnd = null;


	// -------------------------------------------------------------------------


	/**
	 * Common Functions
	 *
	 * @author Takuto Yanagida
	 * @version 2022-08-01
	 */
	
	
	const resizeListeners = [];
	
	function onResize(fn, doFirst = false) {
		if (doFirst) fn();
		resizeListeners.push(throttle(fn));
	}
	
	function onLoad(fn) {
		if ('loading' === document.readyState) {
			document.addEventListener('DOMContentLoaded', fn);
		} else {
			setTimeout(fn, 0);
		}
	}
	
	
	// -----------------------------------------------------------------------------
	
	
	function initResizeEventHandler() {
		window.addEventListener('resize', () => { for (const l of resizeListeners) l(); }, { passive: true });
	}
	
	function throttle(fn) {
		let isRunning;
		function run() {
			isRunning = false;
			fn();
		}
		return () => {
			if (isRunning) return;
			isRunning = true;
			requestAnimationFrame(run);
		};
	}
	
	function asyncTimeout(ms, fn = () => { }) {
		let tid = null;
		let res;
		return {
			set: () => new Promise((r) => {
				res = r
				tid = setTimeout(async () => {
					tid = null;
					await fn();
					r();
				}, ms);
			}),
			clear: () => {
				if (tid) {
					clearTimeout(tid);
					tid = null;
					res();
				}
			}
		};
	}
	
	
	// -----------------------------------------------------------------------------
	
	
	function initViewportDetection(root, cls, offset) {
		const io = new IntersectionObserver((es) => {
			for (const e of es) root.classList[e.isIntersecting ? 'add' : 'remove'](cls);
		}, { rootMargin: `${offset}px 0px` });
		io.observe(root);
	
		document.addEventListener('visibilitychange', () => {
			const v = ('hidden' !== document.visibilityState);
			if (v) {
				const r = root.getBoundingClientRect();
				const h = window.innerHeight;
				if ((0 < r.top && r.top < h) || (0 < r.bottom && r.bottom < h)) {
					root.classList.add(cls);
				}
			} else {
				root.classList.remove(cls);
			}
		});
	}
	
	/**
	 * Slide
	 *
	 * @author Takuto Yanagida
	 * @version 2022-11-03
	 */
	
	class Slide {
	
		static CLS_SCROLL      = 'scroll';
		static CLS_PRE_DISPLAY = 'pre-display';
		static CLS_DISPLAY     = 'display';
	
		#idx  = 0;
		#cap  = null;
		#mnt  = null;
		#type = '';
	
		constructor(li, idx, useCaption = true) {
			this.#idx = idx;
			this.#cap = useCaption ? Caption.create(li) : null;
	
			if (this.#isVideo(li)) {
				this.#mnt  = new MountVideo(li);
				this.#type = 'video';
			} else {
				this.#mnt  = new MountPicture(li);
				this.#type = 'image';
			}
			if (li.classList.contains(Slide.CLS_SCROLL)) {
				this.#mnt.getElement().classList.add(Slide.CLS_SCROLL);
			}
			li.insertBefore(this.#mnt.getElement(), li.firstChild);
			const e = li.querySelector('a') ?? li;
			e.insertBefore(this.#mnt.getElement(), e.firstChild);
		}
	
		getType() {
			return this.#type;
		}
	
		#isVideo(li) {
			if (li.dataset.video) return true;
			const v = li.querySelector(':scope > video, :scope > a > video');
			return null !== v;
		}
	
		onResize() {
			if ('video' === this.#type && !this.#mnt.onResize()) return false;
			if (this.#cap) this.#cap.onResize();
			return true;
		}
	
		onPreDisplay(cur, size) {
			const m = ((this.#idx % size) === cur) ? 'add' : 'remove';
			this.#mnt.getElement().classList[m](Slide.CLS_PRE_DISPLAY);
			if (this.#cap) this.#cap.getElement().classList[m](Slide.CLS_PRE_DISPLAY);
		}
	
		transition(cur, size) {
			this.#mnt.transition((this.#idx % size) === cur, size);
		}
	
		display(cur, size) {
			const m = ((this.#idx % size) === cur) ? 'add' : 'remove';
			this.#mnt.getElement().classList[m](Slide.CLS_DISPLAY);
			if (this.#cap) this.#cap.getElement().classList[m](Slide.CLS_DISPLAY);
	
			this.#mnt.display((this.#idx % size) === cur);
		}
	
		getDuration(timeDur, timeTran, randomTiming) {
			return this.#mnt.getDuration(timeDur, timeTran, randomTiming);
		}
	
	}
	/**
	 * Mount Picture
	 *
	 * @author Takuto Yanagida
	 * @version 2022-11-03
	 */
	
	class MountPicture {
	
		static CLS_PIC  = NS + '-picture';
		static CLS_DUAL = 'dual';
	
		static RANDOM_RATE = 10;
	
		#elm = null;
	
		constructor(li) {
			this.#elm = document.createElement('div');
			this.#elm.classList.add(MountPicture.CLS_PIC);
	
			const is = li.querySelectorAll(':scope > img, :scope > a > img');
			if (is.length) {
				this.#elm.appendChild(is[0]);
				if (1 < is.length) {
					this.#elm.classList.add(MountPicture.CLS_DUAL);
					this.#elm.appendChild(is[1]);
				}
			} else if (li.dataset.img) {
				const i = this.#createImage(li, { src: 'img', srcset: 'imgSrcset', sizes: 'imgSizes' });
				this.#elm.appendChild(i);
				if (li.dataset.imgSub) {
					this.#elm.classList.add(MountPicture.CLS_DUAL);
					const i = this.#createImage(li, { src: 'imgSub', srcset: 'imgSubSrcset', sizes: 'imgSubSizes' });
					this.#elm.appendChild(i);
				}
			}
		}
	
		#createImage(li, keys) {
			const i = document.createElement('img');
			i.src = li.dataset[keys.src];
	
			const srcset = li.dataset[keys.srcset] ?? null;
			const sizes  = li.dataset[keys.sizes]  ?? null;
			if (srcset) i.srcset = srcset;
			if (sizes)  i.sizes  = sizes;
			return i;
		}
	
		getElement() {
			return this.#elm;
		}
	
		transition(isCur, size) {
		}
	
		display(isCur) {
		}
	
		getDuration(timeDur, timeTran, doRandom) {
			if (doRandom) {
				const f = (1 + 0.01 * MountPicture.RANDOM_RATE * (1 - Math.random() * 2));
				return timeDur * f;
			}
			return timeDur;
		}
	
	}
	
	/**
	 * Mount Video
	 *
	 * @author Takuto Yanagida
	 * @version 2022-11-03
	 */
	
	class MountVideo {
	
		static CLS_VIDEO = NS + '-video';
	
		#elm   = null;
		#video = null;
		#ar    = null;
	
		constructor(li) {
			this.#elm = document.createElement('div');
			this.#elm.classList.add(MountVideo.CLS_VIDEO);
	
			const vs = li.querySelectorAll(':scope > video, :scope > a > video');
			if (1 === vs.length) {
				const v = vs[0];
				this.#initialize(v);
				this.#elm.appendChild(v);
				this.#video = v;
			} else if (li.dataset.video) {
				const v = this.#createVideo(li);
				this.#initialize(v);
				this.#elm.appendChild(v);
				this.#video = v;
			}
		}
	
		#createVideo(li) {
			const v = document.createElement('video');
			const s = document.createElement('source');
			s.setAttribute('src', li.dataset.video);
			v.appendChild(s);
			return v;
		}
	
		#initialize(v) {
			v.muted = true;
			v.playsinline = true;
			v.setAttribute('muted', true);
			v.setAttribute('playsinline', true);
			v.addEventListener('loadedmetadata', () => {
				const ar = v.clientWidth / v.clientHeight;
				this.#ar = (0 | (ar * 1000)) / 1000;
			});
		}
	
		getElement() {
			return this.#elm;
		}
	
		transition(isCur, size) {
			if (isCur) {
				this.#video.setAttribute('autoplay', true);
				this.#video.play();
				if (size === 1) this.#video.setAttribute('loop', true);
			}
		}
	
		display(isCur) {
			if (!isCur) {
				this.#video.pause();
				this.#video.currentTime = 0;
			}
		}
	
		getDuration(timeDur, timeTran, doRandom) {
			return this.#video.duration - timeTran;
		}
	
		onResize() {
			if (!this.#ar) return false;
			const arFrame = this.#elm.clientWidth / this.#elm.clientHeight;
			if (this.#ar < arFrame) {
				this.#video.classList.remove('height');
				this.#video.classList.add('width');
			} else {
				this.#video.classList.remove('width');
				this.#video.classList.add('height');
			}
			return true;
		}
	
	}
	
	/**
	 * Caption
	 *
	 * @author Takuto Yanagida
	 * @version 2022-11-03
	 */
	
	class Caption {
	
		static CLS_CAP = NS + '-caption';
	
		static CLS_SUBTITLE = 'subtitle';
		static CLS_CIRCLE   = 'circle';
		static CLS_LINE     = 'line';
	
		static create(li) {
			const elm = li.querySelector(':scope > div, :scope > a > div');
			return elm ? new Caption(elm) : null;
		}
	
		#elm = null;
	
		constructor(elm) {
			this.#elm = elm;
	
			if ('' === elm.className) {
				elm.classList.add(Caption.CLS_CAP);
				elm.classList.add(Caption.CLS_SUBTITLE);
			}
			if (!elm.classList.contains(Caption.CLS_LINE) && !elm.classList.contains(Caption.CLS_CIRCLE)) {
				elm.classList.add(Caption.CLS_SUBTITLE);
			}
			if (elm.classList.contains(Caption.CLS_LINE))     elm.dataset.caption = Caption.CLS_LINE;
			if (elm.classList.contains(Caption.CLS_CIRCLE))   elm.dataset.caption = Caption.CLS_CIRCLE;
			if (elm.classList.contains(Caption.CLS_SUBTITLE)) elm.dataset.caption = Caption.CLS_SUBTITLE;
	
			const ds = elm.querySelectorAll(':scope > div');
			for (const d of ds) this.#wrapText(d);
			this.#wrapText(elm);
			this.#wrapDiv(elm);
		}
	
		#wrapText(elm) {
			for (const n of Array.from(elm.childNodes)) {
				if (3 === n.nodeType) {  // TEXT_NODE
					const str = n.nodeValue.trim();
					if ('' !== str) {
						const e = document.createElement('span');
						e.appendChild(document.createTextNode(str));
						n.parentNode.replaceChild(e, n);
					}
				}
			}
		}
	
		#wrapDiv(elm) {
			const tags = [];
			for (const n of Array.from(elm.childNodes)) {
				if (1 === n.nodeType) {  // ELEMENT_NODE
					if ('DIV' === n.tagName) {
						if (tags.length) {
							const e = document.createElement('div');
							for (const t of tags) e.appendChild(elm.removeChild(t));
							elm.insertBefore(e, n);
							tags.length = 0;
						}
					} else {
						tags.push(n);
					}
				}
			}
			if (tags.length) {
				const e = document.createElement('div');
				for (const t of tags) e.appendChild(elm.removeChild(t));
				elm.appendChild(e);
			}
		}
	
		getElement() {
			return this.#elm;
		}
	
		onResize() {
			if (window.innerWidth < 600) {
				this.#elm.classList.remove(this.#elm.dataset.caption);
				this.#elm.classList.add(Caption.CLS_SUBTITLE);
			} else {
				this.#elm.classList.remove(Caption.CLS_SUBTITLE);
				this.#elm.classList.add(this.#elm.dataset.caption);
			}
		}
	
	}
	/**
	 * Backgrounds
	 *
	 * @author Takuto Yanagida
	 * @version 2022-11-04
	 */
	
	const CLS_BGS = NS + '-backgrounds';
	
	const bgs = [];
	
	function initBackgrounds(size, root, lis) {
		const frame = document.createElement('div');
		frame.classList.add(CLS_BGS);
		root.insertBefore(frame, root.firstChild);
	
		for (let i = 0; i < size; i += 1) {
			const li  = lis[i];
			const bg  = document.createElement('div');
			const img = li.querySelector(':scope img');
			if (img) {
				bg.style.backgroundImage = `url('${img.src}')`;
			}
			frame.appendChild(bg);
			bgs.push(bg);
		}
	}
	
	function transitionBackgrounds(idx, size) {
		for (let i = 0; i < size; i += 1) {
			if (!bgs[i]) continue;
			bgs[i].classList[i === idx ? 'add' : 'remove'](CLS_VISIBLE);
		}
	}
	
	/**
	 * Buttons
	 *
	 * @author Takuto Yanagida
	 * @version 2022-07-28
	 */
	
	
	const CLS_PREV   = NS + '-prev';
	const CLS_NEXT   = NS + '-next';
	const CLS_ACTIVE = 'active';
	const DX_FLICK   = 32;
	
	function initButtons(size, root, transitionFn) {
		const frame   = root.getElementsByClassName(CLS_FRAME)[0];
		const prevBtn = root.getElementsByClassName(CLS_PREV)[0];
		const nextBtn = root.getElementsByClassName(CLS_NEXT)[0];
		if (size === 1) {
			if (prevBtn) prevBtn.style.display = 'none';
			if (nextBtn) nextBtn.style.display = 'none';
			return;
		}
		const prevFn = async () => { await transitionFn(null, -1); };
		const nextFn = async () => { await transitionFn(null,  1); };
		if (prevBtn) prevBtn.addEventListener('click', async () => { frame.dataset.disabled = true; await prevFn(); frame.dataset.disabled = false; });
		if (nextBtn) nextBtn.addEventListener('click', async () => { frame.dataset.disabled = true; await nextFn(); frame.dataset.disabled = false; });
		if (window.ontouchstart === null) _initFlick(frame, prevFn, nextFn, prevBtn, nextBtn);
	}
	
	function _initFlick(frame, prevFn, nextFn, prevBtn, nextBtn) {
		const sts = { prev: null, next: null };
		let px;
	
		frame.addEventListener('touchstart', e => { px = e.touches[0].pageX; });
		frame.addEventListener('touchmove', e => {
			if (px === null) return;
			const x = e.changedTouches[0].pageX;
	
			if (px + DX_FLICK < x) {  // ->
				prevFn();
				_setCommonFlickProcess(e, prevBtn, 'prev', sts);
				px = null;
			} else if (x < px - DX_FLICK) {  // <-
				nextFn();
				_setCommonFlickProcess(e, nextBtn, 'next', sts);
				px = null;
			}
		});
		frame.addEventListener('touchend', () => { px = null; });
	}
	
	function _setCommonFlickProcess(e, btn, which, sts) {
		if (e.cancelable === true) e.preventDefault();
		if (!btn) return;
		clearTimeout(sts[which]);
		btn.classList.add(CLS_ACTIVE);
		sts[which] = setTimeout(() => { btn.classList.remove(CLS_ACTIVE); }, timeTran * 1000 / 2);
	}
	
	/**
	 * Rivets
	 *
	 * @author Takuto Yanagida
	 * @version 2021-06-24
	 */
	
	
	const CLS_RIVETS = NS + '-rivets';
	const CLS_RIVET  = NS + '-rivet';
	
	const rivets = [];
	
	function initRivets(size, root, transitionFn) {
		if (size === 1) return;
		const rs = root.getElementsByClassName(CLS_RIVETS)[0];
		if (!rs) return;
		const dir = size === 2 ? 1 : 0;
	
		for (let i = 0; i < size; i += 1) {
			const idx = i;
			const r = document.createElement('span');
			r.id = id + '-rivet-' + idx;
			r.className = CLS_RIVET;
			r.addEventListener('click', () => { transitionFn(idx, dir); });
			rs.appendChild(r);
			rivets.push(r);
		}
	}
	
	function transitionRivets(idx) {
		for (const r of rivets) r.classList.remove(CLS_VISIBLE);
		if (rivets[idx]) rivets[idx].classList.add(CLS_VISIBLE);
	}
	
	/**
	 * Thumbnails
	 *
	 * @author Takuto Yanagida
	 * @version 2021-06-24
	 */
	
	
	const thumbs = [];
	
	function initThumbnails(size) {
		if (size === 1) return;
		for (let i = 0; i < size; i += 1) {
			const tid = id + '-' + i;
			let it = document.querySelector('*[data-id="' + tid + '"]');
			if (!it) it = document.getElementById(tid);
			thumbs.push(it);
		}
	}
	
	function transitionThumbnails(idx) {
		for (const t of thumbs) {
			if (t) t.classList.remove(CLS_VISIBLE);
		}
		if (thumbs[idx]) thumbs[idx].classList.add(CLS_VISIBLE);
	}
	
	/**
	 * Indicators
	 *
	 * @author Takuto Yanagida
	 * @version 2021-06-23
	 */
	
	
	const CLS_SLIDE_CNT = NS + '-slide-count';
	const CLS_SLIDE_IDX = NS + '-slide-index';
	
	let slideCntElms = [];
	let slideIdxElms = [];
	
	function initIndicators(size, root) {
		slideCntElms = root.querySelectorAll('.' + CLS_SLIDE_CNT);
		slideIdxElms = root.querySelectorAll('.' + CLS_SLIDE_IDX);
		for (const elm of slideCntElms) elm.innerHTML = size;
		for (const elm of slideIdxElms) elm.innerHTML = 1;
	}
	
	function transitionIndicators(idx) {
		for (const elm of slideIdxElms) elm.innerHTML = (idx + 1);
	}
	
	/**
	 * Slide Transition
	 *
	 * @author Takuto Yanagida
	 * @version 2021-06-25
	 */
	
	
	class TransitionSlide {
	
		constructor(size, slides, tranTime) {
			this._size = size;
			this._lis  = slides;
			this._time = tranTime;
	
			for (let i = 0; i < this._size; i += 1) {
				this._lis[i].style.transform = 'translateX(' + (i ? 100 : 0) + '%)';
			}
			setTimeout(() => {
				for (let i = 0; i < this._size; i += 1) {
					this._lis[i].style.opacity = 1;
					this._lis[i].style.transition = 'transform ' + this._time + 's';
				}
			}, 10);
		}
	
		async transition(idx, dir) {
			for (let i = 0; i < this._size; i += 1) {
				this._lis[i].style.transform = (i <= idx) ? 'translateX(0%)' : 'translateX(100%)';
			}
			await asyncTimeout(this._time * 1000).set();
		}
	
	}
	
	/**
	 * Scroll Transition
	 *
	 * @author Takuto Yanagida
	 * @version 2022-11-02
	 */
	
	
	class TransitionScroll {
	
		constructor(size, slides, tranTime) {
			this._size = size;
			this._lis  = slides;
			this._time = tranTime;
	
			this._cur    = 0;
			this._curPsd = 0;
			this._doing  = false;
			this._queue  = [];
	
			const ps = this._calcPosition(0, 1);
			for (let i = 0; i < this._lis.length; i += 1) {
				this._lis[i].style.opacity   = 1;
				this._lis[i].style.transform = `translateX(${ps[i] * 100}%)`;
			}
		}
	
		async transition(idx, dir) {
			this._queue.push((cur, curPsd) => this._doTransition(cur, curPsd, idx, dir));
			if (this._doing) return;
			this._doing = true;
			while (this._queue.length) {
				[this._cur, this._curPsd] = await this._queue.shift()(this._cur, this._curPsd);
			}
			this._doing = false;
		}
	
		async _doTransition(curIdx, curIdxPsd, idx, dir) {
			if (0 === dir && curIdx !== idx) {
				const r = (curIdx < idx) ? idx - curIdx : idx + this._size - curIdx;
				const l = (idx < curIdx) ? curIdx - idx : curIdx + this._size - idx;
				dir = (l < r) ? -1 : 1;
			}
			let ps = this._calcPosition(curIdxPsd, dir);
			for (let i = 0; i < this._lis.length; i += 1) {
				this._lis[i].style.transition = '';
				this._lis[i].style.transform  = `translateX(${ps[i] * 100}%)`;
			}
			await asyncTimeout(100).set();  // Wait
	
			let d = 0;
			if (dir ===  1) d = idx - curIdx;
			if (dir === -1) d = curIdx - idx;
			if (d < 0) d += this._size;
	
			let idxPsd = curIdxPsd;
			for (let i = 0; i < d; i += 1) {
				[ps, idxPsd] = this._shift(ps, idxPsd, dir, this._time / d, this._getTransition(d, i));
				await asyncTimeout(Math.floor(this._time * 1000 / d)).set();
			}
			return [idx, idxPsd];
		}
	
		_getTransition(d, i) {
			if (d === 1) return 'ease';
			if (d === 2) {
				if (i === 0) return 'ease-in';
				if (i === 1) return 'ease-out';
			}
			return 'linear';
		}
	
		_shift(curPs, curIdxPsd, dir, time, tf = 'ease') {
			const lenPsd = this._lis.length;
	
			let idxPsd = curIdxPsd + dir;
			if (lenPsd - 1 < idxPsd) idxPsd = 0;
			if (idxPsd < 0) idxPsd = lenPsd - 1;
	
			const ps = this._calcPosition(idxPsd, dir);
	
			for (let i = 0; i < lenPsd; i += 1) {
				const t = (Math.abs(curPs[i] - ps[i]) === 1) ? `transform ${time}s ${tf}` : '';
				this._lis[i].style.transition = t;
				this._lis[i].style.transform  = `translateX(${ps[i] * 100}%)`;
			}
			return [ps, idxPsd];
		}
	
		_calcPosition(idxPsd, dir) {
			const lenPsd = this._lis.length;
			const ps     = new Array(lenPsd);
	
			const hs = (dir !== -1) ? Math.ceil((lenPsd - 1) / 2) : Math.floor((lenPsd - 1) / 2);
			const rs = lenPsd - 1 - hs;
	
			for (let i = 1; i <= hs; i += 1) {
				let j = idxPsd + i;
				if (lenPsd - 1 < j) j -= lenPsd;
				ps[j] = i;
			}
			for (let i = 1; i <= rs; i += 1) {
				let j = idxPsd - i;
				if (j < 0) j += lenPsd;
				ps[j] = -i;
			}
			ps[idxPsd] = 0;
			return ps;
		}
	
	}
	
	/**
	 * Fade Transition
	 *
	 * @author Takuto Yanagida
	 * @version 2021-06-25
	 */
	
	
	class TransitionFade {
	
		constructor(size, slides, tranTime) {
			this._size = size;
			this._lis  = slides;
			this._time = tranTime;
	
			for (let i = 0; i < this._size; i += 1) {
				this._lis[i].style.opacity = (i === 0) ? 1 : 0;
			}
			setTimeout(() => {
				for (let i = 0; i < this._size; i += 1) {
					this._lis[i].style.transition = 'opacity ' + this._time + 's';
				}
			}, 10);
		}
	
		async transition(idx, dir) {
			for (let i = 0; i < this._size; i += 1) {
				this._lis[i].style.opacity = (i === idx) ? 1 : 0;
				this._lis[i].style.pointerEvents = (i === idx) ? 'auto' : 'none';
			}
			await asyncTimeout(this._time * 1000).set();
		}
	}
	


	// -------------------------------------------------------------------------


	const hasVideo = initSlides();
	if (bgVisible) initBackgrounds(size, root, lis);

	if (hasVideo) setTimeout(tryResizeVideo, 100);
	function tryResizeVideo() {
		const finish = onResizeSlide();
		if (!finish) setTimeout(tryResizeVideo, 100);
	}

	const frame = root.getElementsByClassName(CLS_FRAME)[0];
	if (0 < navigator.maxTouchPoints) {
		frame.addEventListener('pointerenter', e => {
			const m = (e.pointerType === 'mouse') ? 'remove' : 'add';
			frame.classList[m]('touch');
		}, { once: true });
	}

	onLoad(() => {
		initResizeEventHandler();

		initButtons(size, root, transition);
		initThumbnails(size);
		initIndicators(size, root);
		initRivets(size, root, transition);

		initViewportDetection(root, CLS_VIEW, OFFSET_VIEW);

		transition(0, 0);
		console.log(`Gida Slider - Show (#${id}): started`);
		setTimeout(() => root.classList.add(CLS_START), 0);
	});


	// -------------------------------------------------------------------------


	function initSlides() {
		if (effectType === 'scroll' && 1 < size && size < 5) {
			cloneLis();
			if (size === 2) cloneLis();
		}
		const isScroll = root.classList.contains(CLS_SCROLL);
		let hasVideo = false;

		for (let i = 0; i < lis.length; i += 1) {
			if (isScroll) lis[i].classList.add(CLS_SCROLL);

			const ss = new Slide(lis[i], i);
			if ('video' === ss.getType()) hasVideo = true;
			sss.push(ss);
		}
		onResize(onResizeSlide, true);
		switch (effectType) {
			case 'slide' : effect = new TransitionSlide(size, lis, timeTran); break;
			case 'scroll': effect = new TransitionScroll(size, lis, timeTran); break;
			case 'fade'  : effect = new TransitionFade(size, lis, timeTran); break;
			default      : effect = new TransitionSlide(size, lis, timeTran); break;
		}
		return hasVideo;
	}

	function onResizeSlide() {
		let finish = true;
		for (const ss of sss) {
			if (!ss.onResize()) finish = false;
		}
		return finish;
	}

	function cloneLis() {
		for (let i = 0; i < size; i += 1) {
			const li  = lis[i];
			const nls = li.cloneNode(true);
			lis.push(nls);
			li.parentNode.appendChild(nls);
		}
	}


	// -------------------------------------------------------------------------


	let curIdx    = 0;
	let stStep    = null;
	let last      = 0;
	let stReserve = null;

	async function transition(idx, dir) {
		[idx, dir] = getIdxDir(idx, dir);

		const t = window.performance.now();
		if (dir !== 0 && t - last < timeTran * 1000) {
			clearTimeout(stReserve);
			stReserve = setTimeout(() => transition(idx, dir), timeTran * 1000 - (t - last));
			return;
		}
		last = t;

		for (const ss of sss) ss.onPreDisplay(idx, size);
		for (const ss of sss) ss.transition(idx, size);

		transitionBackgrounds(idx, size);
		transitionThumbnails(idx);
		transitionIndicators(idx);
		transitionRivets(idx);

		await effect.transition(idx, dir);
		if (onTransitionEnd) onTransitionEnd();
		curIdx = idx;
		display(idx);
	}

	async function display(idx) {
		for (const ss of sss) ss.display(idx, size);
		if (size === 1) return;

		const dt = sss[idx].getDuration(timeDur, timeTran, randomTiming);
		if (stStep) stStep.clear();
		stStep = asyncTimeout(Math.ceil(dt * 1000), step);
		await stStep.set();
	}

	async function step() {
		if (root.classList.contains(CLS_VIEW) && !root.classList.contains(CLS_PAUSE)) {
			transition(null, 1);
		} else {
			asyncTimeout(timeDur * 1000, step).set();
		}
	}

	function getIdxDir(idx, dir) {
		if (idx === null) {
			idx = curIdx + dir;
			if (size - 1 < idx) idx = 0;
			if (idx < 0) idx = size - 1;
		} else if (dir === 0 && curIdx !== idx) {
			const r = (curIdx < idx) ? idx - curIdx : idx + size - curIdx;
			const l = (idx < curIdx) ? curIdx - idx : curIdx + size - idx;
			dir = (l < r) ? -1 : 1;
		}
		return [idx, dir];
	}


	// -------------------------------------------------------------------------


	const fs = {
		move: idx => transition(idx, size === 2 ? 1 : 0),
		next: ()  => transition((curIdx === size - 1) ? 0          : (curIdx + 1),  1),
		prev: ()  => transition((curIdx === 0       ) ? (size - 1) : (curIdx - 1), -1),

		onTransitionEnd: fn => { onTransitionEnd = fn; }
	};
	window.GIDA.sliders[id] = fs;
	return fs;

}
